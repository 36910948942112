import {
    IsAccessExpired,
    IsRefreshAccessToken,
    LOGIN_URL,
    SetAccessExpire,
    SetAccessToken,
    SetRefreshAfter
} from "@/utils/token";
import {Navigate} from "react-router-dom";
import {POST} from "@/utils/http";

const AuthComponent = ({children}) => {
    if (IsAccessExpired()) {
        return <Navigate to={LOGIN_URL} replace/>
    }

    if (IsRefreshAccessToken()) {
        POST("/auth/refresh").then(res => {
            console.log(res);
            res["access_token"] && SetAccessToken(res["access_token"]);
            res["access_expire"] && SetAccessExpire(res["access_expire"]);
            res["refresh_after"] && SetRefreshAfter(res["refresh_after"]);
        })
    }

    return (<div>{children}</div>)
}

export default AuthComponent;