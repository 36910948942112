import {GetAccessToken} from "@/utils/token";

// const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:9508" : "https://api.cthub.io";
const API_URL = "https://api.cthub.io";

let defaultOptions = {
    params: null,
    body: null,
    headers: {
        'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    credentials: 'include',
    responseType: 'JSON',
    // mode: 'no-cors'
};

const GET = (url, options) => request("GET", url, options);
const POST = (url, options) => request("POST", url, options);
const PUT = (url, options) => request("PUT", url, options);
const DELETE = (url, options) => request("DELETE", url, options);


export {GET, POST, PUT, DELETE,}

const request = (method, url, options) => {
    defaultOptions = isAuth(options);

    url = parseUrl(url, options);

    options = parseBody(options);

    return fetch(url, {method: method, ...defaultOptions, ...options})
        .then(response => {
            let {status, statusText} = response;
            if (status >= 200 && status < 300) {
                let result;
                switch (defaultOptions.responseType.toUpperCase()) {
                    case 'JSON':
                        result = response.json();
                        break;
                    case 'TEXT':
                        result = response.text();
                        break;
                    case 'BLOB':
                        result = response.blob();
                        break;
                    case 'ARRAYBUFFER':
                        result = response.arrayBuffer();
                        break;
                }
                return result;
            }

            return Promise.reject({
                code: 'STATUS ERROR',
                status,
                statusText
            });
        })
        .catch(reason => {
            if (reason && reason.code === 'STATUS ERROR') {
                // 状态码错误
                switch (reason.status) {
                    case 400:
                        // ...
                        break;
                    case 401:
                        window.location.href = LOGIN_URL;
                        return;
                    case 404:
                        // ...
                        break;
                }
            } else if (!navigator.onLine) {
                // @2 网络中断
                // ...
            } else {
                // @3 请求被终止
                // ...
            }
            return Promise.reject(reason);
        })
        .then(handleBusinessResponse);
}

const parseBody = (options) => {
    if (options !== undefined && options["body"] !== undefined) {
        options.body = JSON.stringify(options.body);
    }
    return options;
}

const parseUrl = (url, options) => {
    if (options !== undefined && options["pathVariables"] !== undefined) {
        Object.keys(options["pathVariables"]).map(key => {
            url = url.replace(`:${key}`, options["pathVariables"][key]);
        });
    }

    if (options !== undefined && options["params"] !== undefined) {
        url += '?' + Object.keys(options["params"]).map(key => key + '=' + options["params"][key]).join('&');
    }

    return API_URL + url;
}

const isAuth = (options) => {
    let _defaultOptions = defaultOptions;
    if (options === undefined || options["isAuth"] === undefined || options["isAuth"] === true) {
        _defaultOptions.headers["Authorization"] = "Bearer " + GetAccessToken()
    }
    return _defaultOptions;
}

// const checkResponseStatus = (response) => {
//     let {status, statusText} = response;
//
//     if (status >= 200 && status < 300) {
//         let result;
//         switch (options.responseType.toUpperCase()) {
//             case 'JSON':
//                 result = response.json();
//                 break;
//             case 'TEXT':
//                 result = response.text();
//                 break;
//             case 'BLOB':
//                 result = response.blob();
//                 break;
//             case 'ARRAYBUFFER':
//                 result = response.arrayBuffer();
//                 break;
//         }
//         return result;
//     }
//
//     return Promise.reject({
//         code: 'STATUS ERROR',
//         status,
//         statusText
//     });
// }

// const parseJSON = response => {
//     return response.json();
// };

const handleBusinessResponse = (response) => {
    if (response.code !== undefined && response.message !== undefined) {
        throw response;
    }
    return response;
}