import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Outlet, useNavigate} from "react-router-dom";

import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WebIcon from '@mui/icons-material/Web';

import CopyrightComponent from "@/components/CopyrightComponent";
import {Avatar, Grid, ListItemButton, ListItemIcon, ListItemText, Skeleton} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {GET} from "@/utils/http";
import {gravatar} from "@/utils/gravatar";
import UserSelectorDropdownButton from "@/components/UserSelectorDropdownButton";


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            backgroundColor: "#141414",
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


const AppLayout = () => {
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useMemo(() => createTheme({palette: {mode: prefersDarkMode ? 'dark' : 'light',},}), [prefersDarkMode],);

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => setOpen(!open);

    const [user, setUser] = useState(null);
    const [avatar, setAvatar] = useState("");

    useEffect(() => {
        const userProfile = () => {
            GET("/user/profile").then(res => {
                setUser(res);
                setAvatar(gravatar(res.email));
            });
        }
        userProfile();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="fixed" open={open} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <Toolbar sx={{pr: '24px',}}>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}>
                            {`Cloud Translate`}
                        </Typography>
                        <IconButton color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                        <UserSelectorDropdownButton user={user}/>

                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open} sx={{position: "relative"}}>
                    <Toolbar/>
                    <Toolbar/>
                    <Toolbar sx={{
                        position: 'fixed',
                        top: "64px",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'inherit',
                        backgroundColor: "#121212",
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}>
                        <Grid container>
                            <Grid item xs="auto">
                                {avatar ? (<Avatar src={avatar}/>) : (
                                    <Skeleton variant="circular">
                                        <Avatar/>
                                    </Skeleton>
                                )}
                            </Grid>
                            <Grid item xs sx={{marginLeft: 1, display: 'flex', alignItems: 'center'}}>
                                {open ? (
                                    <Box sx={{width: '100%'}}>
                                        {avatar ? (<Typography>{user?.email}</Typography>) : (
                                            <Skeleton variant="text" width="100%">
                                                <Typography>.</Typography>
                                            </Skeleton>
                                        )}
                                    </Box>
                                ) : ""}
                            </Grid>

                        </Grid>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <ListItemButton onClick={() => navigate(`/`)}>
                            <ListItemIcon><DashboardIcon/></ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItemButton>
                        <Divider sx={{my: 1}}/>
                        <ListItemButton onClick={() => navigate(`/websites`)}>
                            <ListItemIcon><WebIcon/></ListItemIcon>
                            <ListItemText primary="Websites"/>
                        </ListItemButton>


                    </List>
                    <Divider/>
                    <List component="div" sx={{
                        position: 'fixed',
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'inherit',
                        backgroundColor: "#121212",
                    }}>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemIcon>
                                {open ? <KeyboardDoubleArrowLeftIcon/> : <KeyboardDoubleArrowRightIcon/>}
                            </ListItemIcon>
                            {open ? <ListItemText primary="折叠边栏"/> : <ListItemText/>}
                        </ListItemButton>
                    </List>
                </Drawer>
                <Box component="main" sx={{
                    backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                    <Toolbar/>
                    <Outlet/>

                    <CopyrightComponent/>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default AppLayout;







