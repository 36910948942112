import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppLayout from "@/layout/AppLayout";
import App from "@/pages/App";
import AuthLayout from "@/layout/AuthLayout";
import SignIn from "@/pages/auth/SignIn";
import SignUp from "@/pages/auth/SignUp";
import AuthComponent from "@/components/AuthComponent";
import NewWebsites from "@/pages/websites/new";
import WebsitesDetails from "@/pages/websites/details";
import Websites from "@/pages/websites";

document.title = "Console | Cloud Translate";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <AuthComponent>
                        <AppLayout/>
                    </AuthComponent>
                }>
                    <Route index element={<App/>}/>
                    <Route path="websites">
                        <Route path="" element={<Websites/>}/>
                        <Route path="new" element={<NewWebsites/>}/>
                        <Route path=":website_hash" element={<WebsitesDetails/>}/>

                    </Route>
                </Route>
                <Route path="/auth" element={<AuthLayout/>}>
                    <Route path="signin" element={<SignIn/>}/>
                    <Route path="signup" element={<SignUp/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
