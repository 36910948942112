import {Button, Card, CardContent, Container, InputAdornment, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {GET} from "@/utils/http";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function WebsitesDetails(props) {
    const navigate = useNavigate();
    const {website_hash} = useParams();

    const [website, setWebsite] = useState({});
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        GET(`/api/websites/:website_hash`, {pathVariables: {website_hash}}).then(res => {
            console.log(res)
            res["website"] && setWebsite(res["website"]);
        })
        GET("/websites/:website_hash/languages", {pathVariables: {website_hash}}).then(res => {
            console.log(res)
            res["languages"] && setLanguages(res["languages"]);
        })
    }, [website_hash])


    console.log(website_hash)
    return (<>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant={`h4`}>Languages</Typography>

            <Card sx={{mt: 2}}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={"auto"}
                              sx={{display: "flex", alignItems: "center", justifyContent: "center", padding: 0}}>
                            <Typography variant={`body1`}>Origin Domain:</Typography>
                            <Typography variant={`body2`} sx={{marginLeft: 1}}>{website.domain}</Typography>
                        </Grid>
                        <Grid item xs={"auto"} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography variant={`body1`}>Origin Language:</Typography>
                            <Typography variant={`body2`} sx={{marginLeft: 1}}>{website.language}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid container spacing={3} sx={{marginTop: 1, alignItems: "center"}}>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label="search your Language"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                        }}
                    />
                </Grid>
                <Grid item xs={"auto"}>
                    <Button variant="contained">Search</Button>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs={"auto"}>
                    <Button variant="contained" startIcon={<AddCircleOutlineIcon/>}
                            onClick={() => navigate("/websites/new")}>New</Button>
                </Grid>
            </Grid>


            <Grid container spacing={3} sx={{marginTop: `15px`}}>
                {languages.map(language => (<Grid item xs={4} key={language.hash}>
                    <Card>
                        <CardContent>
                            <span className={`fi fi-${language.language}`}></span>
                            <Typography variant="body1">{language.domain}</Typography>
                        </CardContent>
                    </Card>
                </Grid>))}
            </Grid>
        </Container>
    </>);
}

export default WebsitesDetails;
