import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {useNavigate} from 'react-router-dom';
import {POST} from "@/utils/http";
import {SetAccessExpire, SetAccessToken, SetRefreshAfter} from "@/utils/token";

const SignIn = () => {
    document.title = "Sign In | Cloud Translate";

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        POST("/auth/login", {body: {email, password}, isAuth: false}).then(r => {
            console.log(r);
            r["access_token"] && SetAccessToken(r["access_token"]);
            r["access_expire"] && SetAccessExpire(r["access_expire"]);
            r["refresh_after"] && SetRefreshAfter(r["refresh_after"]);
            navigate("/");
        })
    }


    return (<>
        <Box sx={{my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Typography component="h1" variant="h5">{`Cloud Translate By Sign In`}</Typography>
            <Box component="form" noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(event) => setPassword(event.target.value)}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmit}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/auth/signup" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>

            </Box>
        </Box>
    </>);
};

export default SignIn;