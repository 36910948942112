const LOGIN_URL = "/auth/signin"

const ACCESS_TOKEN_KEY = 'access_token';
const ACCESS_EXPIRE_KEY = 'access_expire';
const REFRESH_AFTER_KEY = 'refresh_after';


const GetAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);
const SetAccessToken = (token) => window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
const RemoveAccessToken = () => window.localStorage.removeItem(ACCESS_TOKEN_KEY);
const HasAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY) !== null && window.localStorage.getItem(ACCESS_TOKEN_KEY) !== "";


const GetAccessExpire = () => window.localStorage.getItem(ACCESS_EXPIRE_KEY);
const SetAccessExpire = (expire) => window.localStorage.setItem(ACCESS_EXPIRE_KEY, expire);
const RemoveAccessExpire = () => window.localStorage.removeItem(ACCESS_EXPIRE_KEY);
const HasAccessExpire = () => window.localStorage.getItem(ACCESS_EXPIRE_KEY) !== null && window.localStorage.getItem(ACCESS_EXPIRE_KEY) !== "";

const IsAccessExpired = () => {
    const now = new Date().getTime() / 1000;

    // AccessToken 不存在
    if (!HasAccessToken()) {
        return true;
    }

    return GetAccessExpire() < now;
}


const GetRefreshAfter = () => window.localStorage.getItem(REFRESH_AFTER_KEY);
const SetRefreshAfter = (expire) => window.localStorage.setItem(REFRESH_AFTER_KEY, expire);
const RemoveRefreshAfter = () => window.localStorage.removeItem(REFRESH_AFTER_KEY);
const HasRefreshAfter = () => window.localStorage.getItem(REFRESH_AFTER_KEY) !== null;

const IsRefreshAccessToken = () => HasRefreshAfter() && GetRefreshAfter() !== null && GetRefreshAfter() < new Date().getTime() / 1000;

const ClearAll = () => {
    RemoveAccessToken();
    RemoveAccessExpire();
    RemoveRefreshAfter();
}

export {
    LOGIN_URL,
    ACCESS_TOKEN_KEY,
    ACCESS_EXPIRE_KEY,
    REFRESH_AFTER_KEY,
    GetAccessToken,
    SetAccessToken,
    RemoveAccessToken,
    HasAccessToken,
    GetAccessExpire,
    SetAccessExpire,
    RemoveAccessExpire,
    HasAccessExpire,
    IsAccessExpired,
    GetRefreshAfter,
    SetRefreshAfter,
    RemoveRefreshAfter,
    HasRefreshAfter,
    IsRefreshAccessToken,
    ClearAll,
};
