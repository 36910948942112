import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";
import {POST} from "@/utils/http";
import {SetAccessExpire, SetAccessToken, SetRefreshAfter} from "@/utils/token";

const SignUp = () => {
    document.title = "Sign Up | Cloud Translate";

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(email, password);

        POST("/auth/register", {body: {email, password}, isAuth: false}).then(r => {
            console.log(r);
            r["access_token"] && SetAccessToken(r["access_token"]);
            r["access_expire"] && SetAccessExpire(r["access_expire"]);
            r["refresh_after"] && SetRefreshAfter(r["refresh_after"]);
            navigate("/");
        });
    }

    return (<>
        <Box sx={{my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Typography component="h1" variant="h5">{`Cloud Translate By Sign Up`}</Typography>
            <Box component="div" noValidate sx={{mt: 1}}>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(event) => setPassword(event.target.value)}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmit}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item>
                        <Link href="/auth/signin" variant="body2">
                            {"Already have an account? Sign in"}
                        </Link>
                    </Grid>
                </Grid>

            </Box>
        </Box>

    </>);

}


export default SignUp;