import {Box, Container, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useState} from "react";
import {POST} from "@/utils/http";


function NewWebsites() {
    const navigate = useNavigate();


    const [name, setName] = useState("");
    const [domain, setDomain] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        POST("/websites", {body: {name: name, domain: domain}})
            .then(r => {
                const data = r.data;
                navigate("/websites/" + data.domain);
            })
    }

    return (<>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant={`h4`}>New Website</Typography>

            <Grid container spacing={3} sx={{marginTop: `10px`}}>
                <Grid item xs={5}>
                    <Box>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="name"
                            label="Name"
                            id="name"
                            onChange={(event) => setName(event.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="domain"
                            label="Domain"
                            id="domain"
                            onChange={(event) => setDomain(event.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            onClick={handleSubmit}
                        >
                            Store
                        </Button>

                    </Box>
                </Grid>
            </Grid>

        </Container>
    </>);
}

export default NewWebsites;
