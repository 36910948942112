import Grid from "@mui/material/Grid";
import {Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

function App() {
    document.title = "Dashboard | Cloud Translate";

    const navigate = useNavigate();


    return (<>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant={`h4`}>Dashboard</Typography>

            <Grid container spacing={3} sx={{marginTop: `10px`}}>

            </Grid>

        </Container>
    </>);
}

export default App;
