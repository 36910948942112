import Grid from "@mui/material/Grid";
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Container,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {GET} from "@/utils/http";

function Websites() {
    document.title = "Websites | Cloud Translate";

    const navigate = useNavigate();

    const [websites, setWebsites] = useState([]);

    useEffect(() => {
        GET("/websites").then(res => {
            console.log(res);
            res["websites"] && setWebsites(res["websites"]);
        });
    }, [])


    return (<>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant={`h4`}>Websites</Typography>

            <Grid container spacing={3} sx={{marginTop: `10px`}}>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label="search your website"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                        }}
                    />
                </Grid>
                <Grid item xs={"auto"}>
                    <Button variant="contained">Search</Button>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs={"auto"}>
                    <Button variant="contained" startIcon={<AddCircleOutlineIcon/>}
                            onClick={() => navigate("/websites/new")}>New</Button>
                </Grid>
            </Grid>

            <Grid container spacing={3} sx={{marginTop: `15px`}}>
                {websites.map(website => (<Grid item xs={4} key={website.hash}>
                    <Card>
                        <CardActionArea onClick={() => navigate(`/websites/${website.hash}`)}>
                            <CardContent>
                                <Typography variant="body1">{website.domain}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>))}
            </Grid>
        </Container>
    </>);
}

export default Websites;
