import CryptoJS from "crypto-js";

export const gravatar = (email, size) => {

    if (!email) {
        return "https://cdn.v2ex.com/gravatar/?d=identicon";
    }

    const hash = CryptoJS.MD5(email.trim().toLowerCase()).toString();

    // let url = "https://www.gravatar.com/avatar/" + hash;
    let url = "https://cdn.v2ex.com/gravatar/" + hash;
    if (size) {
        url += "?s=" + size;
    }

    return url;
}