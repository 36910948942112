import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import {useState} from 'react';
import {Avatar, Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import {ClearAll, LOGIN_URL} from "@/utils/token";
import {useNavigate} from "react-router-dom";

const UserSelectorDropdownButton = (props) => {
    let navigate = useNavigate();

    let {user} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const Logout = () => {
        ClearAll();
        navigate(LOGIN_URL);
    }

    return (<React.Fragment>
        <IconButton
            color="inherit"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <AccountCircleIcon/>
        </IconButton>
        <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}
              PaperProps={{
                  elevation: 0,
                  sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                      },
                      '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                      },
                  },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem>
                {user?.email}
            </MenuItem>
            <Divider/>
            <MenuItem>
                <Avatar/> Profile
            </MenuItem>
            <MenuItem>
                <Avatar/> My account
            </MenuItem>
            <Divider/>
            <MenuItem>
                <ListItemIcon>
                    <SettingsIcon fontSize="small"/>
                </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem onClick={Logout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small"/>
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>

        {/*<Popper*/}
        {/*    open={open}*/}
        {/*    anchorEl={anchorRef.current}*/}
        {/*    role={undefined}*/}
        {/*    placement="bottom-start"*/}
        {/*    transition*/}
        {/*    disablePortal*/}
        {/*>*/}
        {/*    {({TransitionProps, placement}) => (*/}
        {/*        <Grow {...TransitionProps}*/}
        {/*              style={{transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',}}>*/}
        {/*            <Paper>*/}
        {/*                <ClickAwayListener onClickAway={handleClose}>*/}
        {/*                    <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button"*/}
        {/*                              onKeyDown={handleListKeyDown}>*/}
        {/*                        <MenuItem onClick={handleClose}>*/}
        {/*                            Profile*/}
        {/*                        </MenuItem>*/}

        {/*                        <MenuItem onClick={handleClose}>My account</MenuItem>*/}
        {/*                        <MenuItem onClick={handleClose}>Logout</MenuItem>*/}
        {/*                    </MenuList>*/}
        {/*                </ClickAwayListener>*/}
        {/*            </Paper>*/}
        {/*        </Grow>*/}
        {/*    )}*/}
        {/*</Popper>*/}
    </React.Fragment>)
}

export default UserSelectorDropdownButton;